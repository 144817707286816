<template>
  <div class="container text-primary">
    <div class="row mt-md-3">
      <div class="col-md-8 mx-auto">
        <div class="card border-0 shadow-sm">
          <div class="card-body px-md-5 py-md-3">
            <div v-if="!tfaToken">
              <div>
                <div class="row mb-4">
                  <div class="col-auto mx-auto text-center">
                    <img
                      src="@/assets/images/brand/full-logo.svg"
                      alt="Swandoola"
                      width="300"
                      class="mb-4"
                    />
                    <h3 class="fw-bold">Practitioner Portal Login</h3>
                  </div>
                </div>
              </div>
              <div>
                <div class="row mb-4" v-if="error">
                  <div class="col">
                    <p class="alert alert-danger">
                      <i class="far fa-info me-2"></i>
                      {{ error }}
                    </p>
                  </div>
                </div>
              </div>
              <form v-if="!requested" @submit.prevent="requestLoginToken">
                <div class="row mb-3">
                  <div class="col my-auto">
                    <label class="d-block mb-2"
                      >Enter your email address to request a secure login
                      link</label
                    >
                    <input
                      v-model="form.email"
                      id="email"
                      type="email"
                      class="form-control"
                      required
                      placeholder="jane@example.com"
                    />
                  </div>
                </div>

                <div class="row mb-4 text-center">
                  <div class="col-md-6 mx-auto">
                    <button
                      type="submit"
                      id="loginTokenBtn"
                      class="btn btn-primary"
                      :disabled="!form.email"
                    >
                      <i v-if="!busy" class="far me-2 fa-key" />
                      <i v-else class="far fa-spinner fa-spin me-2"></i>
                      Request Login Link
                    </button>
                  </div>
                </div>
              </form>

              <div class="row text-center mb-3" v-if="!requested">
                <div class="col-6 mx-auto">
                  <button
                    @click="requestSmsOtp"
                    v-if="!smsSent"
                    id="loginSmsBtn"
                    class="btn btn-outline-primary btn-sm"
                    :disabled="busy"
                  >
                    <i v-if="!busy" class="far me-2 fa-key" />
                    <i v-else class="far fa-spinner fa-spin me-2"></i>
                    Request SMS
                  </button>
                  <div v-if="smsSent" class="rounded alert alert-info mt-2">
                    <div>
                      Thank you, your SMS is on it's way. If you have registered
                      a mobile number with us you will receive an SMS with your
                      One-Time Password to enter above.
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-4" v-if="!requested">
                <div class="col">
                  <div class="alert alert-info text-primary">
                    <h5 class="fw-bold mb-0">Password-less access</h5>
                    <p class="fw-bold mb-0">To access Swandoola</p>
                    <ul class="mt-1">
                      <li>
                        We can send you an email that has a special login link.
                        Clicking on that link will log you in.
                      </li>
                      <li>
                        If you have added your mobile number to Swandoola, we
                        can send you an SMS that has a one-time password. Input
                        this on the next page &amp; you are in.
                      </li>
                    </ul>
                  </div>
                  <p class="my-3">
                    <a
                      href="https://support.swandoola.com/passwordless"
                      class="text-primary"
                      target="_blank"
                      >Click here to find out more about Swandoola security and
                      why we use password-less authentication.</a
                    >
                  </p>
                </div>
              </div>

              <div v-if="requested">
                <div class="row mb-3" v-if="!smsSent">
                  <div class="col">
                    <h5>Method One: Magic Link</h5>
                    <p class="alert alert-info text-primary fw-bold">
                      <i class="far fa-info me-2"></i>
                      We've sent you an email that contains a secure login link.
                      You may close this tab and follow that link to securely
                      gain access.
                    </p>
                  </div>
                </div>
                <div class="row mb-3" v-if="!smsSent">
                  <div v-if="smsSent" class="rounded alert alert-info mt-2">
                    <div>
                      <i class="far fa-info me-2"></i>
                      Thank you, your SMS is on it's way. If you have registered
                      a mobile number with us you will receive an SMS with your
                      One-Time Password to enter above.
                    </div>
                  </div>
                </div>
                <form @submit.prevent="loginWithOtp">
                  <div class="row">
                    <div class="col">
                      <h5 v-if="!smsSent">Method Two: One-Time Password</h5>
                      <p class="alert alert-info text-primary fw-bold mb-4">
                        <i class="far fa-info me-2"></i>
                        Enter the One-Time Password contained in the
                        <span v-if="!smsSent">email</span
                        ><span v-else-if="smsSent == true">SMS</span> that we've
                        sent you.
                      </p>
                      <input
                        id="otp"
                        type="password"
                        class="form-control w-50 mx-auto mb-4"
                        name="otp"
                        required
                        autofocus
                        placeholder="Enter one time password"
                        v-model="form.otp"
                      />
                    </div>
                  </div>
                  <div class="row text-center mb-4">
                    <div class="col">
                      <button class="btn btn-primary mx-auto">
                        Login with Secure OTP
                      </button>
                    </div>
                  </div>
                </form>
                <div class="row text-center">
                  <div class="col">
                    <button
                      class="btn btn-outline-primary btn-sm mx-auto"
                      v-if="!smsSent"
                      :disabled="busy"
                      @click="requestSmsOtp"
                    >
                      Send another SMS
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="tfaToken" class="mb-3">
              <div class="row">
                <div class="col">
                  <div
                    class="alert alert-info text-primary fw-bold"
                    role="alert"
                  >
                    <h4 class="alert-heading">Two-Factor Authentication</h4>
                    <p>
                      We have sent you a secure code via SMS. Please enter the
                      secure code into the box below to gain access. This code
                      will expire in 5 minutes.
                    </p>
                    <hr />
                    <p class="mb-0">
                      You are seeing this because you require 2FA in your User
                      Settings.
                    </p>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col text-center">
                  <form @submit.prevent="authorize2fa">
                    <input
                      type="number"
                      v-model="tfaCode"
                      placeholder="000000"
                      class="form-control w-25 mx-auto text-center mb-3"
                      required
                    />
                    <button type="submit" class="btn btn-primary">
                      Authorize 2FA
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SuccessAlert from "../components/alerts/Success";
import ErrorAlert from "../components/alerts/Error";
import User from "@/models/User";

export default {
  data() {
    return {
      form: {
        email: "",
        otp: "",
        requester: "practitioners-portal",
      },
      requested: false,
      error: null,
      tfaToken: "",
      tfaCode: "",
      busy: false,
      smsSent: false,
    };
  },
  methods: {
    async requestLoginToken() {
      this.busy = true;
      this.error = null;
      this.requested = false;

      const api = new User();
      const { data } = await api.requestLoginToken(this.form.email);

      if (data.error) {
        this.error = data.error;
      } else {
        this.requested = true;
      }
      this.busy = false;
    },
    requestSmsOtp() {
      this.busy = true;
      this.error = null;
      this.smsSent = false;
      this.requested = false;
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/request-sms-otp", this.form)
        .then(({ data }) => {
          console.log(data);
          if (data.error) {
            this.error = data.error;
          } else {
            this.requested = true;
            this.smsSent = true;
            this.$EventBus.$emit("alert", data);
          }
          this.busy = false;
        })
        .catch((error) => {
          this.error =
            "Error has happend, please try again or contact support!";
          this.busy = false;
        });
    },
    loginWithOtp() {
      this.busy = true;
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/login-otp", this.form)
        .then(({ data }) => {
          if (data.error == "invalid-otp") {
            this.error = "Invalid OTP";
          } else if (data.error == "tfa_required") {
            window.location = data.url;
          } else if (!data.error) {
            this.$store.commit("auth/setUser", data.user);
            this.$store.commit("auth/setToken", data.token.access_token);
            this.$store.commit("auth/setAuthed", true);
            this.$router.push({ name: "DashboardView" });
          }
          this.busy = false;
        });
    },
    authorize2fa() {
      this.busy = true;
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/login-2fa", {
          token: this.tfaToken,
          code: this.tfaCode,
        })
        .then(({ data }) => {
          if (data.error == "invalid-credentials") {
            this.error = "Invalid code";
          } else if (data.error == "invalid-2fa") {
            this.error = "Invalid 2FA Code";
          } else if (!data.error) {
            this.$store.commit("auth/setUser", data.user);
            this.$store.commit("auth/setToken", data.token.access_token);
            this.$store.commit("auth/setAuthed", true);

            this.$router.push({ name: "DashboardView" });
          }
          this.busy = false;
        });
    },
    getParams() {
      const params = new URLSearchParams(window.location.search);
      if (params.has("require2fa")) {
        this.tfaToken = params.get("require2fa");
      }
    },
  },
  mounted() {
    this.getParams();
  },
  components: {
    SuccessAlert,
    ErrorAlert,
  },
};
</script>

<style lang="scss">
</style>
